  
.card-container.card {
    max-width: 350px !important;
    padding: 40px 40px;
}
  
.card {
    /* background-color: hsla(128, 48%, 37%, 0.75); */
    padding: 20px 25px 30px;
    margin: 0 auto 25px;
    margin-top: 50px;
    -moz-border-radius: 2px;
    -webkit-border-radius: 2px;
    border-radius: 1rem;
    box-shadow: 6px 6px 8px 0px rgba(0, 0, 0, 0.5);
    /* -moz-box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.3);
    -webkit-box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.3);
    box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.3); */
}

  
  .profile-img-card {
    width: 96px;
    height: 96px;
    margin: 0 auto 10px;
    display: block;
    -moz-border-radius: 50%;
    -webkit-border-radius: 50%;
    border-radius: 50%;
  }
  
  .form-group {
    padding: 0.3rem;
  }
  
  .form-control {
    width: 100%;
  }
  
  label {
    color: black;
  }
  
  .landing-wrapper {
    background-size: 100%;
    background-repeat: no-repeat;
  }
  
  /* button {
    width: 100%;
    color: #fff;
    background-color: 	hsla(211, 46%, 42%, 1);
    border-color: hsla(172, 59%, 38%, 0.5);
    text-align: center;
    vertical-align: middle;
    cursor: pointer;
    -webkit-user-select: none;
    user-select: none;
    border: 1px;
    padding: .375rem .75rem;
    font-size: 1rem;
    border-radius: .3rem;
    transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
  } */